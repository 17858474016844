
import { connect } from "react-redux";
import { Group } from "../../../../../types";
import { Section, Text } from "../StyledComponents";
import { Expert, Name, Avatar, CoverPic, PlayVideo, VideoIcon } from "./styles";
import { AttachedFile } from "../../../../../types/attached-file";
import VideoPlay from "../../../../../components/VideoPlayer";
import { useMarkdown } from "../../../../../hooks/useMarkdown";
import { RemirrorRenderer } from "@remirror/react";
import { createRef, useEffect, useState } from "react";
import BigPlayImage from "../../../../../assets/player/play.svg";
import PauseVideoImage from "../../../../../assets/player/pause.svg";
import { Separator } from "../../../../../components/StyledComponents";
import Markdown from "markdown-to-jsx";
import slugify from "slugify";
import { useWindowSize } from "../../../../../hooks/useWindowSize";

interface ComponentProps {
  group: Group;
  groupStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ group }: ComponentProps) => {
  const { isMobile } = useWindowSize()
  const { typeMap, markMap, jsonStr, textStr } = useMarkdown({
    brief: group?.brief,
  });
  const videoRef = createRef<HTMLVideoElement>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [imageFiles, setImageFiles] = useState<AttachedFile>();
  const [videoFiles, setVideoFiles] = useState<AttachedFile>();
  const [videoSizes, setVideoSizes] = useState<{
    aspectRatio: number;
    width: number;
    height: number;
  }>();

  const handleLoad = () => {
    const videoElement = videoRef?.current;

    if (videoElement) {
      videoElement.currentTime = 0;

      if (videoElement.videoWidth && videoElement.videoHeight) {
        const aspectRatio =
          videoElement?.videoWidth / videoElement?.videoHeight;
        setVideoSizes({
          aspectRatio: aspectRatio,
          width: videoElement.videoWidth,
          height: videoElement?.videoHeight,
        });
      }
    }
  };

  useEffect(() => {
    if (videoRef?.current) {
      videoRef.current.addEventListener('play', () => setIsPlaying(true));
      videoRef.current.addEventListener('pause', () => setIsPlaying(false));
    }
  }, []);

  useEffect(() => {
    if (!!group && !!group.group_video) {
      if (group?.group_video?.mime?.includes("image")) {
        setImageFiles(group.group_video);
      }

      if (group?.group_video?.mime?.includes("video")) {
        setVideoFiles(group.group_video);
      }
    }
  }, [group]);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleVideoEnded = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // Reset currentTime to 0
      setIsPlaying(false); // Show the "Play" icon
    }
  };

  return (
    <Section>
      {/* <Expert>
        <Avatar>
          <img
            src={group?.profile_pic?.url || group?.expert?.profile_pic?.url}
            alt={group?.expert?.profile_pic?.name}
          />
        </Avatar>
        <Name>{group?.creator_name}</Name>
      </Expert> */}

      <Separator height={{ desktop: "26px", mobile: "26px" }} />

      <Text style={{ textAlign: "center", maxWidth: "880px" }}>
        {!jsonStr && !!textStr && <Markdown>{textStr}</Markdown>}

        {!!jsonStr && (
          <RemirrorRenderer
            json={jsonStr}
            typeMap={typeMap}
            markMap={markMap}
          />
        )}
      </Text>

      {!!videoFiles && (
        <>
          <Separator height={{ desktop: "44px", mobile: "44px" }} />

          <CoverPic
            style={{
              display: "block",
              ...(videoSizes && {
                width: "auto",
                minHeight: isMobile ? 0 : 425,
                maxHeight: isMobile
                  ? 460 // En móviles, máximo 460px para todos los videos
                  : videoSizes.height > videoSizes.width // Detecta si es portrait
                    ? videoSizes.height > 640
                      ? videoSizes.height / 2.5
                      : videoSizes.height
                    : Math.min(500, videoSizes.height), // Máximo 500px para wide
                maxWidth:
                  videoSizes.height > videoSizes.width // Detecta si es portrait
                    ? 320 // Ancho máximo para videos portrait
                    : videoSizes.width > 1280
                      ? videoSizes.width / 2.5
                      : videoSizes.width, // Ancho máximo actual para wide
              }),
            }}
          >
            <video
              onContextMenu={(e) => e.preventDefault()}
              controlsList="nodownload disablepictureinpicture controls duration nofullscreen"
              ref={videoRef}
              onClick={togglePlayPause}
              width="100%"
              key={`attached-file-${slugify(videoFiles.original_name)}`}
              style={{
                display: "block",
                ...(videoSizes && {
                  minHeight: isMobile ? 0 : 425,
                  maxHeight: isMobile
                    ? 460 // En móviles, máximo 460px para todos los videos
                    : videoSizes.height > videoSizes.width // Detecta si es portrait
                      ? videoSizes.height > 640
                        ? videoSizes.height / 2.5
                        : videoSizes.height
                      : Math.min(500, videoSizes.height), // Máximo 500px para wide
                  maxWidth:
                    videoSizes.height > videoSizes.width // Detecta si es portrait
                      ? 320 // Ancho máximo para videos portrait
                      : videoSizes.width > 1280
                        ? videoSizes.width / 2.5
                        : videoSizes.width, // Ancho máximo actual para wide
                }),
              }}
              onLoad={handleLoad}
              onLoadedMetadata={handleLoad}
              onEnded={handleVideoEnded} // Listen for the ended event
            >
              <source src={videoFiles.signed_url} type={videoFiles.mime} />
              Tu navegador no soporta Video.
            </video>

            <PlayVideo onClick={togglePlayPause}>
              <VideoIcon
                src={isPlaying ? PauseVideoImage : BigPlayImage}
                alt={isPlaying ? "Stop Video" : "Play"}
                isPlaying={isPlaying}
              />
            </PlayVideo>

            <VideoPlay
              controls
              video={videoFiles.signed_url}
              mime={videoFiles.mime}
              name="group-cover-video"
            />
          </CoverPic>


          <Separator height={{ desktop: "33px", mobile: "42px" }} />
        </>
      )}
    </Section>
  );
};

const state = ({ groupStore }) => {
  const { data: group, states: groupStates } = groupStore.group;

  return {
    group,
    groupStates,
  };
};

export default connect(state)(Component);
