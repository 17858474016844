import Vimeo from '@u-wave/react-vimeo';
import { useState, useEffect } from 'react'
import Link from "../../../../../components/Link";
import linkIcon from "../../../../../assets/icons/open-link.svg";
import crossIcon from "../../../../../assets/icons/cross.svg";
import Icon from "../../../../../components/Icon";
import { InLine } from '../../Preview/styles';
import { VideoContainer } from './styles'
import { useWindowSize } from "../../../../../hooks/useWindowSize";


interface ComponentProps {
  videoId: string;
  width?: number;
  height?: number;
  showLink?: boolean;
  controls?: boolean;
  isPreview?: boolean;
}


const VimeoVideo = ({ controls = true, videoId, width, height, showLink = true, isPreview = false }: ComponentProps) => {
  const [isVimeoEmbeddable, setIsVimeoEmbeddable] = useState<boolean | null>(null);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [isVertical, setIsVertical] = useState(false);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    setForceUpdate((prev) => prev + 1);
    const checkEmbeddability = async () => {
      try {
        const response = await fetch(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}`);
        const data = await response.json();
        
        if(!!data){
          setIsVertical(data.width < data.height);
        }

        // Verifica si el video es embebible
        setIsVimeoEmbeddable(!!data);
      } catch (error) {
        console.error('Error al verificar la posibilidad de embebido del video de Vimeo:', error);
        setIsVimeoEmbeddable(false); // Maneja errores y establece a false
      }
    };

    if (!!videoId) {
      checkEmbeddability();
    }
  }, [videoId])


  // Only include width and height props if they are provided
  const vimeoProps = {
    video: `https://vimeo.com/${videoId}`,
    color: "6c5ce7d9",
    showTitle: false,
    //...(width ? { width } : {}),   // Include width only if provided
    //...(height ? { height } : {}), // Include height only if provided
  };

  if (isVimeoEmbeddable === null)
    return <p style={{ fontStyle: "italic" }}>Cargando Video Embebido...</p>


  return (
    isVimeoEmbeddable ? (
      <VideoContainer isMobile={isMobile} isVertical={isVertical} isPreview={isPreview} >
          <Vimeo {...vimeoProps} speed={true} key={forceUpdate} responsive={true} />
      </VideoContainer>
    ) : (
      <>
        {showLink ? (<Link
          target="_blank"
          href={`https://vimeo.com/${videoId}`}
          options={{
            type: "filled",
            skin: "purple",
            size: "lg",
            native: false,
          }}
        >
          <InLine style={{ gap: "5px" }}>
            Abrir contenido
            <Icon size={"15px"} icon={linkIcon} color="white" />
          </InLine>
        </Link>) : (
          <div style={{ display: "flex" }}>
            <div style={{ padding: "3px" }}><Icon size={"15px"} color="black" icon={crossIcon} /></div>
            <span style={{ "fontStyle": "italic", marginLeft: "3px", fontSize: "15px" }}>
              El video cargado es invalido o no cuenta con las configuraciones de privacidad adecuadas para ser Embebido en otros sitios. Por ello, mostramos directamente el enlace.
            </span>
          </div>
        )}
      </>
    )
  );
};

export default VimeoVideo;
