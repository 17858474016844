import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getGroupBySlug } from '../../../store/actions/group';
import { Group, PlanDto, Subscription, User } from '../../../types';
import Success from '../components/CheckoutPaymentSuccess';
import ErrorPayment from '../components/CheckoutPaymentError';
import ErrorSubscription from '../components/CheckoutSubscriptionError';
import { getUrlParamByName } from '../../../helpers/get-url-param';
import { getFreeSubscriptions } from '../../../helpers/getFreeSubscriptions';
import { isSubscribed } from '../../../helpers/isSubscribed';
import PurchaseDetails from './components/PurchaseDetails';
import { CheckoutContainer, Column } from './styles'
import { hideModal, showModal } from '../../../store/actions/modal';
import ProcessSubscriptionModal from './components/ProcessSubscriptionModal';
import action from "../../../helpers/rest-client";
import MercadoPagoForm from '../components/MercadoPagoForm';
import MpOwnForm from '../components/MpOwnForm';
import { getPlan } from '../../../store/actions/plan';
import { hybridEncryptData } from '../../../helpers/card-encrypter';

interface ComponentProps {
  isLoggedIn: boolean
  userSubscriptions: Subscription[]
  userSubscriptionStates: {
    loading: boolean
    success: boolean
    error: string | boolean
  }
  group: Group,
  plan: PlanDto,
  user: User
}

const testGroups = [
  2850, 4117, 5306, 2201, 6964, 3354, 11185, 5979, 5776
]

const Component = ({
  isLoggedIn,
  userSubscriptions,
  userSubscriptionStates,
  plan,
  group,
  user
}: ComponentProps) => {
  const dispatch = useDispatch();

  const [gatewayError, setGatewayError] = useState(false)
  const [appError, setAppError] = useState(false)
  const [success, setSuccess] = useState(false)

  const groupSlug = useMemo(() => getUrlParamByName('groupSlug'), [])
  const planId = useMemo(() => getUrlParamByName('planId'), [])
  const changePlan = useMemo(() => getUrlParamByName('changePlan'), [])

  const onSuccess = (subscription) => {
    setSuccess(true)
    setGatewayError(false)
    setAppError(false)
  }

  const onError = (error) => {
    if (error?.response?.data?.source === 'gateway') {
      setGatewayError(true)
    } else if (error?.response?.data?.source === 'application') {
      setAppError(true)
    } else {
      setAppError(true)
    }
    setSuccess(false)
  }

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(userSubscriptions, group?.id)
    } else if (!isLoggedIn || group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), group?.id)
    } else {
      return false
    }
  }, [userSubscriptions, group, isLoggedIn])


  const price = useMemo(() => {
    if (!!plan) {
      const selectedPlanDiscount = plan?.discounts?.length > 0 ? plan?.discounts[0] : null
      return (plan?.price || 0) - (selectedPlanDiscount?.amount || 0);
    }

    return 0
  }, [plan])

  const publicKey = useMemo(() => {
    if (!!plan) {
      return plan?.gateway?.credentials?.public?.public_key;
    }

    return 0
  }, [plan])

  const discount = useMemo(() => {
    if (!!plan) {
      const selectedPlanDiscount = plan?.discounts?.length > 0 ? plan?.discounts[0] : null
      return selectedPlanDiscount && [selectedPlanDiscount]
    }

    return []
  }, [plan])

  const onSubmitOne = useCallback(async (cardData) => {
    dispatch(showModal('modal-process-new-subscription'))

    const payload = {
      plan_id: planId,
      discounts: discount,
      payment_details: cardData,
      idempotencyKey: `${user?.id}-${planId}`
    }

    action.Post({
      url: "/api/subscription/payment",
      body: payload,
    }).then((response: Subscription) => {
      onSuccess(response)
    }).catch((error) => {
      onError(error)
    }).finally(() => {
      dispatch(hideModal())
    });
  }, [discount, price, planId]);

  const onSubmitTwo = useCallback(async (cardData) => {
    dispatch(showModal('modal-process-new-subscription'))

    const encryptedCardData = await hybridEncryptData(JSON.stringify(cardData)).catch((error) => {
      console.error("Encryption failed:", error);
      throw new Error("Failed to encrypt card data");
    });


    const payload = {
      plan_id: planId,
      discounts: discount,
      payment_details: {
        card_data: encryptedCardData,
        payer: {
          ...(process.env.REACT_APP_ENVIRONMENT !== "production" ? { email: "test_user_1799191595@testuser.com" } : { email: user?.email }),
          // email: user?.email
        }
      },
      idempotencyKey: `${user?.id}-${planId}`
    }

    action.Post({
      url: "/api/subscription/payment",
      body: payload,
    }).then((response: Subscription) => {
      onSuccess(response)
    }).catch((error) => {
      console.error("API call failed:", error);
      onError(error)
    }).finally(() => {
      dispatch(hideModal())
    });
  }, [discount, price, planId, user]);

  useEffect(() => {
    if (!!groupSlug && (!group || (!!group && group?.slug !== groupSlug))) {
      dispatch(getGroupBySlug(groupSlug));
    }
  }, [groupSlug]);

  useEffect(() => {
    if (!!planId && (!plan || (!!plan && plan?.id !== Number(planId)))) {
      dispatch(getPlan({ idPlan: Number(planId) }));
    }
  }, [planId]);

  useEffect(() => {
    if (isUserSubscribed && changePlan !== "true") {
      window.location.href = `/group/${groupSlug}/library`
    }
  }, [isUserSubscribed, groupSlug])

  useEffect(() => {
    if (!planId || !groupSlug) {
      const referrer = document.referrer;
      if (referrer) {
        window.location.href = referrer;
      } else if (groupSlug) {
        window.location.href = `/group/${groupSlug}`;
      } else {
        window.location.href = `/404`;
      }
    }
  }, [planId, groupSlug])

  if (!isLoggedIn || !group || !plan || userSubscriptionStates.loading || plan?.group?.id !== group?.id || plan?.status === "deleted" || !user) {
    return <></>
  }

  return (
    <>
      <CheckoutContainer>
        {!gatewayError && !appError && !success && (
          <>
            <Column>
              {/* {testGroups.includes(group?.id) ? ( */}
                <MpOwnForm
                  onError={onError}
                  onSuccess={onSuccess}
                  onSubmit={onSubmitTwo}
                  publicKey={publicKey || ""}
                  title={"Datos de pago"}
                  price={price}
                  submitText="Suscribirme"
                  user={user}
                />
              {/* ) : ( */}
                {/* <MercadoPagoForm
                  onError={onError}
                  onSuccess={onSuccess}
                  onSubmit={onSubmitOne}
                  publicKey={publicKey || ""}
                  title={"Datos de pago"}
                  price={price}
                  submitText="Suscribirme"
                  user={user}
                />
              )} */}
            </Column>
            <PurchaseDetails />
          </>
        )}
      </CheckoutContainer>

      <ProcessSubscriptionModal />

      {(!gatewayError && appError && !success) && <ErrorSubscription />}
      {(gatewayError && !appError && !success) && <ErrorPayment title={<>Suscripción rechazada</>} text={<>Hola {user?.name}. No te preocupes, esto sucede frecuentemente. Alguno de los datos puede estar incorrectos, tu tarjeta es invalida o no es soportada para este tipo de pagos automáticos. Prueba reintentando con el mismo u otro método de pago.</>} />}
      {(!gatewayError && !appError && success) && <Success />}
    </>
  );
};

const state = ({ userStore, groupStore, planStore }) => {
  const { isLoggedIn } = userStore;
  const { data: userSubscriptions, states: userSubscriptionStates } = userStore.subscriptions;
  const { data: group } = groupStore?.group;
  const { data: plan } = planStore?.onePlan;
  const { data: user } = userStore.information;

  return {
    isLoggedIn,
    user,
    userSubscriptions,
    userSubscriptionStates,
    group,
    plan
  };
};

export default connect(state)(Component);