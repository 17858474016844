import React, { useEffect, useState, ChangeEvent, FormEvent, useCallback, useMemo } from 'react';
import {
  FormContainer,
  Title,
  Subtitle,
  Field,
  Input,
  Select,
  CombinedFields,
  Button,
  Label,
  Row,
  IconsContainer,
  Error,
  ErrorText,
} from './styles';
import visa from '../../../../assets/credit-cards/visa.svg'
import master from '../../../../assets/credit-cards/mastercard.svg'
import cabal from '../../../../assets/credit-cards/cabal.svg'
import american from '../../../../assets/credit-cards/american-express.svg'
import naranja from '../../../../assets/credit-cards/naranja.svg'
import cvv from '../../../../assets/custom-checkout-mp/cvv.svg'
import error from '../../../../assets/custom-checkout-mp/error.svg'
import lock from '../../../../assets/custom-checkout-mp/lock.svg'
import actions from '../../../../helpers/rest-client';

declare global {
  interface Window {
    MP_DEVICE_SESSION_ID?: string;
  }
}


interface User {
  name: string;
  email: string;
}

interface CheckoutFormProps {
  onSuccess,
  onError,
  onSubmit,
  user: User;
  price: number;
  publicKey: string;
  title?: JSX.Element | string;
  subtitle?: string;
  submitText: string;
  changeEmail?: boolean;
  beforeForm?: JSX.Element | string;
  afterForm?: JSX.Element | string;
}

interface FormData {
  cardNumber: string;
  expirationDate: string;
  cvv: string;
  cardHolderName: string;
  dni: string;
  documentType: string;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  onSuccess,
  onError,
  onSubmit,
  user,
  price,
  publicKey,
  title,
  subtitle,
  submitText,
  changeEmail = true,
  beforeForm,
  afterForm,
}) => {
  const [isDeviceIdLoading, setIsDeviceIdLoading] = useState(true);

  const [formData, setFormData] = useState<FormData>({
    cardNumber: '',
    expirationDate: '',
    cvv: '',
    cardHolderName: '',
    dni: '',
    documentType: 'DNI',
  });
  
  const useMercadoPagoDeviceId = () => {
    const [deviceId, setDeviceId] = useState<string | null>(null);
  
    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://www.mercadopago.com/v2/security.js';
      script.setAttribute('view', 'checkout');
      script.async = true;
  
      script.onload = () => {
        // Obtén el Device ID generado por el script
        const id = window.MP_DEVICE_SESSION_ID;
        setDeviceId(id || null);
        
        setIsDeviceIdLoading(false); // Device ID cargado
      };
  
      script.onerror = () => {
        setIsDeviceIdLoading(false); // Manejo de error
        setDeviceId(null)
        console.error('Error al cargar el script de Mercado Pago');
      };
  
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script); // Limpia el script al desmontar el componente
      };
    }, []);
  
    return deviceId;
  };

  const deviceId = useMercadoPagoDeviceId(); // Obtén el Device ID

  const [currentCardIcon, setCurrentCardIcon] = useState<string | null>('');
  const [currentBin, setCurrentBin] = useState<string | null>('');

  const [errors, setErrors] = useState<Record<string, string>>({});

  const cardTypeLengths = {
    visa: [13, 16],
    mastercard: [16],
    amex: [15],
    maestro: [13, 19],
    cabal: [16],
    naranja: [16],
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};

    if(currentBin){
      const cardType = getCardType(currentBin)
      console.log('aca', cardType)

      if(cardType){
        const validLengths = cardTypeLengths[cardType.type];
        console.log(validLengths)
        const cardNumberLength = formData.cardNumber.replace(/\s/g, '').length;
        
        if (!validLengths.includes(cardNumberLength)) {
          newErrors.cardNumber = `Revisa este dato: Debe tener ${validLengths.join(' o ')} dígitos.`;
        }
      } else {
        newErrors.cardNumber = `Dato obligatorio`;
      }
    }

    if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(formData.expirationDate)) {
      newErrors.expirationDate = "Dato obligatorio"; // 'La fecha de vencimiento debe ser MM/AA.';
    } else {
      const [month, year] = formData.expirationDate.split("/").map(Number);
      const currentYear = new Date().getFullYear() % 100; // Last two digits of the current year
      const currentMonth = new Date().getMonth() + 1; // Months are 0-based
    
      if (year < currentYear || (year === currentYear && month < currentMonth)) {
        newErrors.expirationDate = "Fecha invalida";
      }
    }

    if (!/^\d{3,4}$/.test(formData.cvv)) {
      newErrors.cvv = "Minimo 3 o 4 digitos";//'El CVV debe tener 3 o 4 dígitos.';
    }

    if (formData.cardHolderName.trim() === '') {
      newErrors.cardHolderName = "Dato obligatorio";//'El nombre no puede estar vacío.';
    }

    if (!/^\d{7,8}$/.test(formData.dni)) {
      newErrors.dni = "Dato obligatorio";//'El DNI debe tener 7 u 8 dígitos.';
    }

    // Validar documento según tipo
    switch (formData.documentType) {
      case 'DNI':
        if (!/^\d{7,8}$/.test(formData.dni)) {
          newErrors.dni = "El DNI debe tener entre 7 y 8 dígitos.";
        }
        break;
      case 'CI':
        if (!/^\d{6,8}$/.test(formData.dni)) {
          newErrors.dni = "La Cédula debe tener entre 6 y 8 dígitos.";
        }
        break;
      case 'LC':
      case 'LE':
        if (!/^\d{7}$/.test(formData.dni)) {
          newErrors.dni = `El ${formData.documentType} debe tener 7 dígitos.`;
        }
        break;
      default:
        newErrors.dni = "Tipo de documento no válido.";
        break;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'cardNumber') {
      const formatted = value.replace(/\D/g, '').replace(/(\d{4})/g, '$1 ').trim();
      setFormData((prev) => ({ ...prev, cardNumber: formatted }));

      const bin = value.replace(/\D/g, '').substring(0, 6); // Extraer los primeros 6 dígitos

      if (bin.length === 6 && bin !== currentBin) { // Solo llamar si el BIN cambió
        setCurrentBin(bin)
        const cardTypeIcon = detectCardType(bin)
        
        if(cardTypeIcon)
          setCurrentCardIcon(cardTypeIcon);
      }
    }
  };

  const cardTypes = [
    { type: 'visa', pattern: /^4/, icon: visa },
    { type: 'mastercard', pattern: /^5[1-5]/, icon: master },
    { type: 'amex', pattern: /^3[47]/, icon: american },
    { type: 'naranja', pattern: /^589562|^58956[3-9]/, icon: naranja },
  ];

  const detectCardType = (bin) => {
    const match = cardTypes.find((card) => card.pattern.test(bin));
    return match ? match.icon : null;
  };

  const getCardType = (bin) => {
    const match = cardTypes.find((card) => card.pattern.test(bin));
    return match;
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    if (name === 'cardNumber') {
      const formatted = value
        .replace(/\D/g, '') // Remove non-digit characters
        .substring(0, 20) // Limit to 20 digits
        .replace(/(\d{4})/g, '$1 ') // Add space every 4 digits
        .trim(); // Remove trailing spaces
      setFormData((prev) => ({ ...prev, cardNumber: formatted }));
    } else if (name === 'expirationDate') {
      const formatted = value
        .replace(/\D/g, '') // Remove non-digit characters
        .substring(0, 4) // Limit to 4 characters
        .replace(/^(\d{2})(\d{0,2})$/, (match, p1, p2) => (p2 ? `${p1}/${p2}` : p1)); // Format as MM/YY
      setFormData((prev) => ({ ...prev, expirationDate: formatted }));
    } else if (name === 'dni') {
      const formatted = value.replace(/\D/g, '').substring(0, 8); // Limit to 8 digits
      setFormData((prev) => ({ ...prev, dni: formatted }));
    } else if (name === 'cvv') {
      const formatted = value.replace(/\D/g, '').substring(0, 4); // Limit to 4 digits
      setFormData((prev) => ({ ...prev, cvv: formatted }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
    
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!validateForm()) return;

      // Espera hasta que el deviceId esté disponible
    const waitForDeviceId = async () => {
      const maxWaitTime = 5000; // Tiempo máximo de espera en ms
      const intervalTime = 100; // Intervalo de chequeo en ms
      let elapsedTime = 0;

      while (isDeviceIdLoading && elapsedTime < maxWaitTime) {
        console.log("Esperando a que el Device ID esté disponible...");
        await new Promise((resolve) => setTimeout(resolve, intervalTime));
        elapsedTime += intervalTime;
      }
    };


    try {
      await waitForDeviceId(); // Espera el deviceId antes de proceder

      const cardData = {
        card_number: formData.cardNumber.replace(/\s/g, ''),
        expiration_month: formData.expirationDate.split('/')[0],
        expiration_year: `20${formData.expirationDate.split('/')[1]}`,
        security_code: formData.cvv,
        cardholder: {
          name: formData.cardHolderName,
          identification: {
            type: formData.documentType,
            number: formData.dni,
          },
        },
        device_id: deviceId
      };

      
      onSubmit(cardData);
    } catch (error) {
      console.error('Error:', error);
      onError(error as Error);
    }
  };

  return (
    <FormContainer>
      <Title>Datos de pago</Title>
      <div style={{ height: 22 }} ></div>
      <Subtitle>Tarjeta de crédito o débito</Subtitle>
      <div style={{ height: 12 }} ></div>
      <IconsContainer>
        <img src={visa} alt="Visa" />
        <img src={master} alt="Mastercard" />
        <img src={american} alt="Amex" />
        <img src={cabal} alt="Cabal" />
        <img src={naranja} alt="Cabal" />
      </IconsContainer>
      <div style={{ height: 38 }} ></div>
      <form onSubmit={handleSubmit}>
        <Row>
          <Field>
            <Label isValid={!errors.cardNumber}>Número de tarjeta</Label>
            <Input
              type="text"
              name="cardNumber"
              placeholder="1234 5678 9012 3456"
              value={formData.cardNumber}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              isValid={!errors.cardNumber}
            />
            {errors.cardNumber && (
              <Error>
                <img src={error} alt="Error" />
                <ErrorText>{errors.cardNumber}</ErrorText>
              </Error>
            )}
            <IconsContainer style={{
              position: "absolute",
              top: 31,
              right: 10,
              margin: 0,
            }}>
              {currentCardIcon && <img src={currentCardIcon} alt={"Card Icon"} width="30px" />}
            </IconsContainer>
          </Field>
        </Row>
        <Row>
          <Field>
            <Label isValid={!errors.expirationDate}>Vencimiento</Label>
            <Input
              type="text"
              name="expirationDate"
              placeholder="MM/AA"
              value={formData.expirationDate}
              onChange={handleInputChange}
              isValid={!errors.expirationDate}
            />
            {errors.expirationDate && (
              <Error>
                <img src={error} alt="Error" />
                <ErrorText>{errors.expirationDate}</ErrorText>
              </Error>
            )}
          </Field>
          <Field>
            <Label isValid={!errors.cvv}>Código de seguridad</Label>
            <Input
              type="text"
              name="cvv"
              placeholder="123"
              value={formData.cvv}
              onChange={handleInputChange}
              isValid={!errors.cvv}
              icon={cvv}
            />
            {errors.cvv && (
              <Error>
                <img src={error} alt="Error" />
                <ErrorText>{errors.cvv}</ErrorText>
              </Error>
            )}
            <IconsContainer style={{
              position: "absolute",
              top: 31,
              right: 10,
              margin: 0,
            }}>
              <img src={cvv} alt="cvv" width="39px" />
            </IconsContainer>
          </Field>
        </Row>
        <Row>
          <Field>
            <Label isValid={!errors.cardHolderName}>Nombre del titular</Label>
            <Input
              type="text"
              name="cardHolderName"
              placeholder="Nombre como aparece en la tarjeta"
              value={formData.cardHolderName}
              onChange={handleInputChange}
              isValid={!errors.cardHolderName}
            />
            {errors.cardHolderName && (
              <Error>
                <img src={error} alt="Error" />
                <ErrorText>{errors.cardHolderName}</ErrorText>
              </Error>
            )}
          </Field>
        </Row>
        <Row>
          <Field>
            <Label isValid={!errors.dni}>Documento del titular</Label>
            <CombinedFields>
              <Select
                name="documentType"
                value={formData.documentType}
                onChange={handleInputChange}
                isValid={!errors.dni}
                style={{ maxWidth: 76 }}
              >
                <option value="DNI">DNI</option>
                <option value="CI">Cédula</option>
                <option value="LC">L.C.</option>
                <option value="LE">L.E.</option>
                <option value="Otro">Otro</option>
              </Select>
              <Input
                type="text"
                name="dni"
                placeholder="12345678"
                value={formData.dni}
                onChange={handleInputChange}
                isValid={!errors.dni}
              />
            </CombinedFields>
            {errors.dni && (
              <Error>
                <img src={error} alt="Error" />
                <ErrorText>{errors.dni}</ErrorText>
              </Error>
            )}
          </Field>
        </Row>
        <Row>
          <Button type="submit">
            <img src={lock} alt="Lock" />
            {submitText}
          </Button>
        </Row>
      </form>
    </FormContainer>
  );
};

export default CheckoutForm;

