import * as Yup from "yup";
import services from "../../../services";

let typingTimeout;

const allowedDomains = [
  "aol.com",
  "gmail.com",
  "outlook.com",
  "live.com",
  "hotmail.com",
  "hotmail.com.ar",
  "hotmail.co.uk",
  "hotmail.fr",
  "hotmail.de",
  "hotmail.it",
  "hotmail.es",
  "hotmail.ca",
  "hotmail.com.br",
  "hotmail.co.jp",
  "hotmail.com.mx",
  "msn.com",
  "yahoo.com",
  "icloud.com",
  "mac.com",
  "fibertel.com.ar",
  "arnet.com.ar",
  "speedy.com.ar",
  "ciudad.com.ar",
  "uolsinectis.com.ar",
];

/**
 * Calcula la similitud entre dos cadenas usando la distancia de Levenshtein.
 * Retorna un valor entre 0 y 1, donde 1 significa que las cadenas son idénticas.
 */
const calculateSimilarity = (str1, str2) => {
  const len1 = str1.length;
  const len2 = str2.length;

  const dp = Array.from({ length: len1 + 1 }, () => Array(len2 + 1).fill(0));

  for (let i = 0; i <= len1; i++) dp[i][0] = i;
  for (let j = 0; j <= len2; j++) dp[0][j] = j;

  for (let i = 1; i <= len1; i++) {
    for (let j = 1; j <= len2; j++) {
      if (str1[i - 1] === str2[j - 1]) {
        dp[i][j] = dp[i - 1][j - 1];
      } else {
        dp[i][j] = Math.min(dp[i - 1][j], dp[i][j - 1], dp[i - 1][j - 1]) + 1;
      }
    }
  }

  const distance = dp[len1][len2];
  return 1 - distance / Math.max(len1, len2);
};

/**
 * Obtiene un dominio sugerido basado en una similitud mínima del 40%.
 */
const getSuggestedDomain = (input) => {
  const domain = input.split("@")[1];
  if (!domain) return null;

  let bestMatch: string | null = null;
  let highestSimilarity = 0;

  allowedDomains.forEach((allowed) => {
    const similarity = calculateSimilarity(domain.toLowerCase(), allowed.toLowerCase());
    if (similarity > highestSimilarity) {
      highestSimilarity = similarity;
      bestMatch = allowed;
    }
  });

  // Devuelve el dominio sugerido si la similitud es mayor al 40%
  return highestSimilarity >= 0.7 ? bestMatch : null;
};

export const schema = ({ validationRunning, initialProfile }) => Yup.object().shape({
  name: Yup.string().required("Ingresa tu nombre"),
  last_name: Yup.string().required("Ingresa tu apellido"),
  email: Yup.string()
    .required("Ingresa tu corréo electrónico")
    .trim("El corréo electrónico no puede contener espacio")
    .test(
      "checkUserEmailExist",
      `Ya has creado una cuenta con este email en algún momento. Puedes <a target='_blank' href='/${initialProfile}/signin'>Iniciar Sesión</a>`,
      function async(email) {
        //clear timeout if user is typing and timeout is set
        if (typingTimeout) clearTimeout(typingTimeout);

        return new Promise((resolve, reject) => {
          //delay until user stops typing

          typingTimeout = setTimeout(async () => {
            //check if email is valid
            if (!email) return resolve(true);

            validationRunning(true)

            const { response } = await services.auth.validateEmail(email);

            validationRunning(false)

            if (response?.status > 500) {
              return resolve(
                this.createError({
                  message: "Error inesperado al validar el e-mail",
                })
              );
            }

            if (response?.status > 400) {
              return resolve(true);
            }

            if (!response || response?.status < 400) {
              return resolve(false);
            }
          }, 1000);
        });
      }
    ).test(
      "suggestValidDomain",
      "Correo no válido. ¿Quisiste decir {suggestedDomain}?",
      function (email) {
        if (!email) return true;

        // Convertir el email a minúsculas para garantizar una comparación consistente
        const normalizedEmail = email.toLowerCase();
        const suggestedDomain = getSuggestedDomain(normalizedEmail);
        const domain = normalizedEmail.split("@")[1];

        if (suggestedDomain && suggestedDomain !== domain) {
          return this.createError({
            message: `Correo no válido. ¿Quisiste decir ${normalizedEmail.split("@")[0]}@${suggestedDomain}?`,
          });
        }

        return true;
      }
    ).email("Ingresa un corréo electrónico válido"),
  password: Yup.string().required("Ingresa tu contraseña").min(6, 'La contraseña debe tener al menos 6 caracteres'),
  "confirmar-password": Yup.string()
    .required("Vuelve a ingresar tu contraseña")
    .oneOf(
      [Yup.ref("password"), null],
      "Las contraseñas ingresadas nos no coinciden."
    ),
  initial_profile: Yup.string(),
});

interface FormValues {
  name: string;
  last_name: string;
  email: string;
  password: string;
  "confirmar-password": string;
  initial_profile: string;
}

export const initialValues: FormValues = {
  name: "",
  last_name: "",
  email: "",
  password: "",
  "confirmar-password": "",
  initial_profile: "",
};
