import { createRef, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import dateHandlder from "../../../../../helpers/dates-hanlder";
import { Group, User } from "../../../../../types";
import Image from "../../../../../components/Image";
import { PlanDto } from "../../../../../types/plan.dto";
import { DiscountDto } from "../../../../../types/discount";
import constants from "../../../../../constants";
import SubscribeButton from "../SubscibeButton";
import Clock from "../../../../../components/Clock";
import cancelation from "../../../../../assets/cancelation.svg";
import secure from "../../../../../assets/secure.svg";
import paymentreturn from "../../../../../assets/paymentreturn.svg";
import ReactSelect from '../../../../../components/FormikForm/ReactSelect'
import flagArgentina from "../../../../../constants/countries/flags/flag-AR.png"
import flagUnitedStates from "../../../../../constants/countries/flags/flag-US.png"
import flagEuropeanUnion from "../../../../../constants/countries/flags/flag-UE.png"
import moreVerticalIconBlack from '../../../../../assets/icons/chevron-down-black.svg'
import Dropdown from '../../../../../components/DropdownBis'
import { isOwnGroup } from "../../../../../helpers/isOwnGroup";
import { getCurrencyFromLocation } from '../../../../../helpers/location';

import {
  Cards,
  Card,
  Owner,
  OldPrice,
  CurrentPrice,
  PlanCurrencyAndPeriod,
  Start,
  Duration,
  ExplainText,
  List,
  ListItem,
  Price,
  Currency,
  Amount,
  Period,
  LocalPrice,
  CardCol,
  Title,
  PlanName,
  DiscountWrapper,
  DiscountCol,
  CurrencyLabel,
  Button,
  SelectCurrency,
  FeaturedPrice,
  FeaturePriceLabel,
  DiscountAmount,
  Center,
  SecondaryCurrency
} from "./styles";
import { useWindowSize } from "../../../../../hooks/useFormHook";
import { isSubscribed } from "../../../../../helpers/isSubscribed";
import { getFreeSubscriptions } from "../../../../../helpers/getFreeSubscriptions";
import RegistrationClosingDate from "../RegistrationClosingDate";
import { formatPrice } from "../../../../../helpers/price-formatter";
import { getUrlParamByName } from "../../../../../helpers/get-url-param";

const types = {
  community: {
    label: "Comunidad",
    period_unit: null,
  },
  experience: {
    label: "Taller",
    period_unit: null,
  },
  free_experience: {
    label: "Taller",
    period_unit: null,
  },
  membership: {
    label: "Membresía",
    period_unit: "month",
  },
};

const periods = {
  hour: { plural: "horas", singular: "hora" },
  day: { plural: "días", singular: "día" },
  week: { plural: "semanas", singular: "semana" },
  month: { plural: "meses", singular: "mes" },
  year: { plural: "años", singular: "año" },
};

const periodsUnitsTranslations = {
  "month": { singular: "mes", plural: "meses" },
  "quarter": { singular: "trimestre", plural: "trimestres" },
  "half-year": { singular: "semestre", plural: "semestres" },
  "year": { singular: "año", plural: "años" },
}

const durationUnitsTranslations = {
  "hour": { singular: "hora", plural: "horas" },
  "day": { singular: "día", plural: "días" },
  "week": { singular: "semana", plural: "semanas" },
  "month": { singular: "mes", plural: "meses" },
  "year": { singular: "año", plural: "años" },
}

const currencies = [
  {
    flag: flagArgentina,
    iso: "ARS",
    label: "AR$",
    description: "Pesos Argentinos (ARS)",
  },
  {
    flag: flagEuropeanUnion,
    iso: "EUR",
    label: "€",
    description: "Euros (EUR)",
  },
  {
    flag: flagUnitedStates,
    iso: "USD",
    label: "US$",
    description: "Dolares Americanos (USD)",
  },
];

const CreateCurrencyLabel = ({ currency }) => {
  return (
    <CurrencyLabel>
      <Image src={currency?.flag} width="28px" height="20px" alt="" />
      {currency?.iso}
    </CurrencyLabel>
  )
}

const Benefits = () => {
  return (
    <List>
      <ListItem>
        <Image src={secure} width="28px" height="28px" alt="Secure" />
        <span>
          SSL
          <br />
          Pago Seguro
        </span>
      </ListItem>

      <ListItem>
        <Image src={cancelation} width="28px" height="28px" alt="Cancelation" />
        <span>
          Cancelas
          <br />
          cuando quieras
        </span>
      </ListItem>

      <ListItem>
        <Image
          src={paymentreturn}
          width="28px"
          height="28px"
          alt="Payment Return"
        />
        <span>
          Devolución
          <br />
          garantizada
        </span>
      </ListItem>
    </List>
  );
};

interface ComponentProps {
  group: Group;
  sticky?: boolean;
  callback?: ({
    aboveFold,
    belowFold,
  }: {
    aboveFold: boolean;
    belowFold: boolean;
  }) => void;
  aboveFold?: boolean;
  belowFold?: boolean;
  subscriptions;
  user: User;
  isLoggedIn: boolean;
}

const Component = ({
  group,
  user,
  sticky,
  callback,
  aboveFold,
  belowFold,
  subscriptions,
  isLoggedIn,
}: ComponentProps) => {
  const { isMobile, isTabletPortrait, isDesktop, isTabletLandscape } =
    useWindowSize();
  const [plans, setPlans] = useState<PlanDto[]>();
  // const [discount, setDiscount] = useState<DiscountDto>();
  const [currency, setCurrency] = useState<string>();



  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);
  const cardRef = createRef<HTMLDivElement>();
  const currencySelectRef = createRef<HTMLDivElement>();

  const [pinned, setPinned] = useState(false);
  const [suscriptionEnded, setSuscriptionEnded] = useState<boolean>();


  useEffect(() => {
    const fetchCurrencyAndSet = async () => {
      if (!!plans && plans.filter((p) => p.status === "active").length > 0) {
        try {
          const currency = await getCurrencyFromLocation();

          if(!currency){
            setSelectedCurrency(group?.main_currency);
          } else {
            const currencyPriorities = {
              EUR: ["EUR", "USD", "ARS"],
              USD: ["USD", "EUR", "ARS"],
              ARS: ["ARS", "USD", "EUR"],
            };
  
            for (const tmpCurrency of currencyPriorities[currency || group?.main_currency]) {
              // Check if there are active plans with the current tmpCurrency
              const activePlans = group.plans.filter(
                (p) => p.status === "active" && p.currency === tmpCurrency
              );
  
              if (activePlans.length > 0) {
                // Set the selected currency and break the loop
                setSelectedCurrency(tmpCurrency);
                break;
              }
            }
          }
        } catch (error) {
          console.error("Error fetching currency:", error);
        }
      }
    };

    fetchCurrencyAndSet();
  }, [plans]);


const getFechaComienzo = (date) => {
  const { day, month, year } = dateHandlder.dateSpliter(date);
  return `${day.number}/${month.number}/${year}`;
};

const getFechaInscripcion = (date) => {
  const { day, month, year, hours, minutes } = dateHandlder.dateSpliter(date);
  return `Las inscripciones cierran el: ${day.number}/${month.number}/${year} a las ${hours}:${minutes} horas.`
}

const getPeriod = (duration, unit) => {
  if (unit) {
    return `${duration} ${duration > 1 ? periods[unit].plural : periods[unit].singular
      }`;
  }
  return "";
};

const handleCurrencyChange = (data) => {
  setSelectedCurrency(data)
}

const isUserAuthenticated = useMemo(() => {
  return isLoggedIn || group?.group_type === 'free_experience'
}, [isLoggedIn, group])

const isUserSubscribed = useMemo(() => {
  if (isLoggedIn) {
    return isSubscribed(subscriptions, group?.id)
  } else if (!isLoggedIn || group?.group_type === 'free_experience') {
    return isSubscribed(getFreeSubscriptions(), group?.id)
  } else {
    return false
  }
}, [subscriptions, group, isLoggedIn])

const paymentPeriodTranslated = (plan) => {
  if (plan["period_unit"] && plan["period"]) {
    const periodSelected = plan["period"]; //Cantidad de cobros
    const selectedPeriodUnit = periodsUnitsTranslations[plan["period_unit"]] //Meses / Trimestres / Años / Etc...
    return `Pagas ${plan["period"] <= 1 ? "por" : `cada ${plan["period"]}`} ${selectedPeriodUnit[periodSelected > 1 ? "plural" : "singular"]}.`
  } else {
    return ""
  }
}

const paymentDiscountTranslated = (plan) => {
  const discount = plan["discounts"][0];

  if (discount) {
    const periodSelected = discount["period"] / plan["period"]; //Cantidad de cobros
    return `${periodSelected > 1 ? `Los primeros ${periodSelected} pagos` : 'Pago'} con descuento.`
  } else {
    return ""
  }
}

const explainText = (plan: PlanDto) => {
  const paymentCicles = plan['billing_cycles'] ? `${plan['billing_cycles']} cobros en total.` : "";
  const paymentPeriod = plan["type"] === "charge" ? "Pagá 1 sola vez." : paymentPeriodTranslated(plan);
  const paymentDiscount = paymentDiscountTranslated(plan);

  return `${paymentCicles} ${paymentPeriod} ${paymentDiscount}`;
}

const planPeriod = (plan) => {
  if (plan) {
    if (plan.type === 'subscription') {
      const isPlural = plan?.period && plan?.period > 1;
      const period = plan?.period_unit && periodsUnitsTranslations[plan?.period_unit]
      return `Cada ${plan?.period} ${period[isPlural ? "plural" : "singular"]}`
    } else {
      return "Pago único"
    }
  }
}

const orderedItems = () => {
  let selectedCurrencyPlans = plans?.filter((item) => item.currency === selectedCurrency) || [];

  // Check if there are items
  if (selectedCurrencyPlans && selectedCurrencyPlans.length > 1) {
    const featuredIndex = selectedCurrencyPlans?.findIndex(item => item.featured === true);

    // Check if there is a featured item in the array
    if (featuredIndex !== -1) {
      // Remove the featured item from the array
      const featuredItem = selectedCurrencyPlans?.splice(featuredIndex, 1)[0];
      // Insert the featured item at the second position
      selectedCurrencyPlans?.splice(1, 0, featuredItem);
    }
  }

  return selectedCurrencyPlans
}

useEffect(() => {
  const observer = new IntersectionObserver(
    (entries) => {
      const [entry] = entries;
      if (!!callback) {
        callback({
          aboveFold: entry.boundingClientRect.top < 0,
          belowFold: entry.boundingClientRect.top > 0,
        });
      }
    },
    { threshold: [1] }
  );

  if (cardRef.current) {
    observer.observe(cardRef.current);
  }

  return () => {
    observer.disconnect();
  };
}, [cardRef]);

useEffect(() => {
  if (!!group) {
    setPlans(group?.plans);
  }

  if (!!group?.registration_closing_date) {
    setSuscriptionEnded(
      dateHandlder.isExpired(group?.registration_closing_date)
    );
  }
}, [group]);

useEffect(() => {
  const scrollTo = getUrlParamByName("scrollTo")

  if (scrollTo === 'price' && currencySelectRef?.current && group) {
    const fixedBarHeight = 130;
    const scrollPosition = currencySelectRef.current.getBoundingClientRect().top + window.scrollY - fixedBarHeight;
    window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    // currencySelectRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}, [currencySelectRef, group]);


if (isUserSubscribed) {
  return <></>;
}


return (
  <div>
    {
      group?.group_type === "free_experience" ? (
        <FeaturedPrice isFeatured={true} hasDiscount={false} style={{ minHeight: 191 }}>
          <Card ref={cardRef}>
            <Price>
              <Amount>
                <>Gratuito</>
              </Amount>
            </Price>

            <div style={{ height: 30 }} />

            <SubscribeButton options={{ block: true }} plan={group?.plans[0]} />
          </Card>
        </FeaturedPrice>
      ) : (
        <>
          <SelectCurrency ref={currencySelectRef}>
            Elegí tu moneda

            <Dropdown
              staticLabel={false}
              label={<CreateCurrencyLabel currency={currencies.find(item => item.iso === selectedCurrency)} />}
              icon={moreVerticalIconBlack}
              styles={{
                wrapper: {
                  border: "1px solid #EFF0F6",
                  padding: "15px 18px",
                  borderRadius: 15,
                  gap: 15
                },
                toggle: { color: "#514F6E" },
                list: {
                  minWidth: 100, padding: "8px 0px",
                  top: "100%",
                  border: "1px solid rgb(239, 240, 246)",
                  borderRadius: "0px 0px 15px 15px",
                  width: "auto",
                  left: "-1px",
                  right: "-1px",
                },
                item: { padding: "0px 18px", width: "100%" },
                // icon: { color: "#170F49" }
              }}
            >


              {Array.from(new Set(plans?.map(p => p.currency))).map(currency => (
                <Button
                  onClick={() => handleCurrencyChange(currency)}
                  key={`dropdown-currency-${currency}`}
                  type="button"
                  style={{ display: "flex" }}
                >
                  <CreateCurrencyLabel currency={currencies.find(item => item.iso === currency)} />
                </Button>
              ))}
            </Dropdown>
          </SelectCurrency>

          <div style={{ height: 40 }} />

          <Cards totalItems={plans?.filter((item) => item.currency === selectedCurrency).length || 1} thereAreDiscount={!!orderedItems().find(item => item.discounts.length > 0)}>
            {orderedItems().map(item => {
              const discount = item.discounts[0];
              const secondaryCurrency = !!item?.secondary_currency_rate ?
                constants.currencies.filter(c => c.iso === item?.showable_secondary_currency)[0]?.iso : null

              return (
                <>
                  <FeaturedPrice isFeatured={item?.featured} hasDiscount={!!discount}>
                    <FeaturePriceLabel isFeatured={item?.featured}>El precio más elegido</FeaturePriceLabel>
                    <Card ref={cardRef}>
                      <PlanName>{item.external_name}</PlanName>

                      <div style={{ height: 27 }} />

                      <Price>
                        <Amount>
                          {group?.group_type === "free_experience" ? (
                            <>Gratuito</>
                          ) : (
                            <>
                              <OldPrice>{!!discount && `$${formatPrice(item?.price)}`}</OldPrice>
                              <CurrentPrice>
                                $
                                {!!discount
                                  ? formatPrice((item?.price || 0) - (item?.discounts[0]?.amount || 0))
                                  : formatPrice(item?.price)}
                              </CurrentPrice>
                            </>
                          )}
                        </Amount>

                        <div style={{ height: 6 }} />

                        <PlanCurrencyAndPeriod>
                          <Currency>{item.currency}</Currency> <Period>/{planPeriod(item)}</Period>
                        </PlanCurrencyAndPeriod>

                        {!!item?.secondary_currency_rate && secondaryCurrency && (
                          <>
                            <div style={{ height: 15 }} />
                            <SecondaryCurrency>
                              <LocalPrice>
                                <p style={{ margin: "0" }}>
                                  $
                                  {
                                    Math.round(
                                      (item?.secondary_currency_rate || 0) *
                                      ((item?.price || 0) - (item?.discounts[0]?.amount || 0))
                                    ).toLocaleString(
                                      secondaryCurrency === "ARS" ? "es-AR" : "en-US"
                                    )}
                                  <span style={{ marginLeft: "2px" }}>{secondaryCurrency}</span>
                                </p>
                                {secondaryCurrency === "ARS" && (
                                  <span style={{ fontSize: "12px", margin: "0", top: "-10px", position: "relative" }}>(+ impuestos en Argentina)</span>
                                )}
                              </LocalPrice>

                            </SecondaryCurrency>
                          </>
                        )}
                      </Price>

                      <div style={{ height: 30 }} />

                      <SubscribeButton options={{ block: true }} showDiscount plan={item} />

                      <div style={{ height: 16 }} />

                      <ExplainText>{explainText(item)}</ExplainText>
                    </Card>

                    {!!discount && !suscriptionEnded && new Date(discount?.valid_till) >= new Date() && (
                      <DiscountWrapper>
                        <DiscountCol>
                          <span>Estas ahorrando:</span>
                          <DiscountAmount>${formatPrice(item?.discounts[0]?.amount || 0)}</DiscountAmount>
                        </DiscountCol>
                        <div style={{ height: 34, width: 1, backgroundColor: "white" }} />
                        <DiscountCol>
                          <span>Promoción válida hasta:</span>
                          <div style={{ height: "4px" }} />
                          <Clock
                            date={discount?.valid_till}
                            onEnd={() => window.location.reload()}
                          />
                        </DiscountCol>
                      </DiscountWrapper>
                    )}
                  </FeaturedPrice>
                </>
              )
            })}
          </Cards>
        </>
      )
    }

    <div style={{ height: isMobile ? 70 : 120 }} />

    <Center>
      <RegistrationClosingDate />
    </Center>

    <div style={{ height: 28 }} />

    <Benefits />
  </div >
)
};

const state = ({ groupStore, userStore }) => {
  const { isLoggedIn } = userStore;
  const { data: group } = groupStore.group;
  const { data: subscriptions } = userStore.subscriptions;
  const { data: user } = userStore.information;

  return {
    isLoggedIn,
    user,
    group,
    subscriptions,
  };
};

export default connect(state)(Component);
