import styled from 'styled-components';

export const FormContainer = styled.div`
  max-width: 350px;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; /* Mayor aire vertical entre campos */
  width: 100%;
  position: relative;
`;

export const Title = styled.h2`
  margin: 0px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
`;

export const Subtitle = styled.p`
  margin: 0px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: left;
  width: 100%;
`;



export const Label = styled.label<{ isValid: boolean }>`
  box-sizing: border-box;
  color: ${({ isValid }) => (isValid ? 'rgb(26, 31, 39)' : 'rgb(240, 89, 104)')};
  cursor: default;
  display: block;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 4px;
  text-align: left;
  text-size-adjust: 100%;
  text-transform: uppercase;
  width: 100%;
  word-break: break-word;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

export const Input = styled.input<{ isValid: boolean, icon?: string }>`
  box-sizing: border-box;
  color: rgb(45, 52, 54);
  background-color: rgb(248, 248, 248);
  border: 1px solid ${({ isValid }) => (isValid ? 'rgb(163, 167, 183)' : 'rgb(240, 89, 104)')};
  border-radius: 12px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  display: block;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: 46px;
  line-height: 19px;
  min-height: 38px;
  padding: 12px;
  position: relative;
  text-align: left;
  text-size-adjust: 100%;
  text-transform: uppercase;
  unicode-bidi: isolate;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

   &:focus {
    border-color: ${({ isValid }) => (isValid ? '#3f3ab8' : 'rgb(240, 89, 104)')};
    box-shadow: 0px 0px 0px 3px ${({ isValid }) => (isValid ? 'rgba(63, 58, 184, 0.3)' : 'rgb(240, 89, 104, .25)')};
  }
`;

// export const Select = styled.select`
//   width: 100%;
//   padding: 12px 14px;
//   font-size: 16px;
//   border: 1px solid #ccc;
//   border-radius: 8px;
//   outline: none;
//   appearance: none;
//   cursor: pointer;
//   background: white;
// `;

export const Select = styled.select<{ isValid?: boolean }>`
position: relative;
  width: 100%;
  padding: 12px 14px;
  font-size: 16px;
  border: 1px solid #ccc;
   border: 1px solid ${({ isValid }) => (isValid ? 'rgb(163, 167, 183)' : 'rgb(240, 89, 104)')};
  border-radius: 8px;
  outline: none;
  appearance: none;
  cursor: pointer;
  background: url('data:image/svg+xml;utf8,<svg data-testid="3" role="img" width="39" height="27" viewBox="0 0 39 27" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="cvv3Title_noFocus"><title id="cvv3Title_noFocus">3 dígitos del dorso</title><g style="opacity: 0.5;"><rect x="1" y="2" width="36" height="24" rx="4" stroke="var(--disabled-text-color)" stroke-width="1.5"></rect><rect x="7" y="17" width="24" height="5" rx="1" fill="var(--disabled-text-color)"></rect><rect x="1" y="6" width="36" height="5" fill="var(--disabled-text-color)"></rect><circle cx="31" cy="8" r="8" fill="var(--text-secondary-color)"></circle><path d="M26.8464 7.92383L25.9201 9.0415L26.4875 9.43457L27.2087 8.21094L27.9333 9.47217L28.4973 9.05859L27.5983 7.95117L28.9348 7.63672L28.7263 6.9668L27.4685 7.50342L27.5881 6.02344H26.8977L27.0036 7.48291L25.7355 6.93603L25.5305 7.5957L26.8464 7.92383ZM30.7505 7.92383L29.8242 9.0415L30.3916 9.43457L31.1128 8.21094L31.8374 9.47217L32.4014 9.05859L31.5024 7.95117L32.8389 7.63672L32.6304 6.9668L31.3726 7.50342L31.4922 6.02344H30.8018L30.9077 7.48291L29.6396 6.93603L29.4346 7.5957L30.7505 7.92383ZM34.6546 7.92383L33.7283 9.0415L34.2957 9.43457L35.0169 8.21094L35.7415 9.47217L36.3055 9.05859L35.4065 7.95117L36.743 7.63672L36.5345 6.9668L35.2767 7.50342L35.3963 6.02344H34.7059L34.8118 7.48291L33.5438 6.93603L33.3387 7.5957L34.6546 7.92383Z" fill="var(--input-background-color)"></path></g></svg>') no-repeat right 12px center;
  background-size: 16px;
  background-color: rgb(248, 248, 248);

  &:focus {
    border-color: ${({ isValid }) => (isValid ? '#3f3ab8' : 'rgb(240, 89, 104)')};
    box-shadow: 0px 0px 0px 3px ${({ isValid }) => (isValid ? 'rgba(76, 175, 80, 0.3)' : 'rgb(240, 89, 104, .25)')};
  }
`;

export const CombinedFields = styled.div`
  display: flex;

  & > select {
    border-top-right-radius:0;
    border-bottom-right-radius:0;
  }

  & > input {
    border-top-left-radius:0;
    border-bottom-left-radius:0;
    margin-left: -1px;
  }
`

export const Error = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`

export const ErrorText = styled.p`
  display: inline-block;
  box-sizing: border-box;
  color: rgb(240, 89, 104);
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0px 0px 0px 4px;
  max-width: 100%;
  word-break: break-word;
`;

export const Button = styled.button`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  font-size: 16px;
  background-color: #6c5ce7;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: uppercase;

  &:hover {
    background-color: #574dcf;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const CardType = styled.div`
  margin-top: -15px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #888;
  text-align: right;
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  img {
    width: 32px;
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 15px; /* Espaciado entre los campos */
  width: 100%;
`;