
import { Buffer } from 'buffer';


const publicKeyPem = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAnsY+J3FCdtN61zhhInYU
wtrgBlkfO4QWsGNPjjGI5VwC5Whhh+ApPchQxB4lvaB3473YFZOp9PvzcosYP2Vc
5X4PUiVp8Z3mf6FVW4k4BxJE1Fm77rxknDVW88A4ekZF2zazlko4KJmBbUn8LCqo
AluzCdeO54MeuW3Nu/c/xFpYJM5/3iF5wXtpK7iVc4gw0tU3NTFWzfkc8Rsq/TDx
ugwl7yqoAwu08JQgiD6SzeVFl5emaZfW8lI2IZpVTFDWIXp60JrR8XaOB9sbv7s7
DSx5HfkDAV7dVDfU99VcKOS1CbDo6IIy4h/nyMNAo3aHLDQdbV11KENN0QOjs+PU
kwIDAQAB
-----END PUBLIC KEY-----`;

export const hybridEncryptData = async (body: string) => {
  const key = crypto.getRandomValues(new Uint8Array(32)); // AES key
  const iv = crypto.getRandomValues(new Uint8Array(16)); // AES IV

  // Encode the body
  const encoder = new TextEncoder();
  const bodyBytes = encoder.encode(body);

  // Encrypt the body with AES
  const algorithm = { name: "AES-CBC", iv };
  const cryptoKey = await crypto.subtle.importKey(
    "raw",
    key,
    algorithm,
    false,
    ["encrypt"]
  );

  const encryptedBuffer = await crypto.subtle.encrypt(algorithm, cryptoKey, bodyBytes);
  const encrypted = Buffer.from(new Uint8Array(encryptedBuffer)).toString("hex");

  // Encrypt the AES key with the public RSA key
  const publicKey = await crypto.subtle.importKey(
    "spki",
    decodeBase64(publicKeyPem), // Base64 decoding helper
    { name: "RSA-OAEP", hash: "SHA-256" },
    false,
    ["encrypt"]
  );

  const encryptedKeyBuffer = await crypto.subtle.encrypt(
    { name: "RSA-OAEP" },
    publicKey,
    key
  );
  const encryptedKey = Buffer.from(new Uint8Array(encryptedKeyBuffer)).toString("hex");

  return {
    d: encrypted, // Encrypted data
    k: encryptedKey, // Encrypted AES key
    i: Buffer.from(iv).toString("hex"), // IV in hex
  };
};

// Helper to decode base64 PEM to binary
const decodeBase64 = (pem: string) => {
  const b64 = pem
    .replace(/-----BEGIN PUBLIC KEY-----|-----END PUBLIC KEY-----|\n/g, "")
    .trim();
  return Uint8Array.from(atob(b64), (c) => c.charCodeAt(0));
};


