import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Group, PlanDto, Subscription, User } from '../../../types';
import SecureTechnology from '../components/SecureTechnology'
import Faqs from '../../Group/components/MPVerifyYourPaymentInformationSection/components/Faq';
import { getUrlParamByName } from '../../../helpers/get-url-param';
import ProcessSubscriptionModal from './components/ProcessSubscriptionModal';
import PurchaseDetails from './components/PurchaseDetails';
import {
  CheckoutContainer,
  Column,
  ShowInMobile,
  ShowInDesktop
} from './styles'
import action from "../../../helpers/rest-client";
import MercadoPagoForm from '../components/MercadoPagoForm';
import { useTheme } from 'styled-components';
import Image from '../../../components/Image'
import { hideModal, showModal } from '../../../store/actions/modal';
import mercadoPagoImg from '../../../assets/mercado-pago.png'

import Success from './components/CheckoutPaymentSuccess';
import ErrorPayment from '../components/CheckoutPaymentError';
import ErrorSubscription from '../components/CheckoutSubscriptionError';
import MpOwnForm from '../components/MpOwnForm';
import { group } from 'console';
import { hybridEncryptData } from '../../../helpers/card-encrypter';
interface ComponentProps {
}

const Component = ({ }: ComponentProps) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<{
    group: Group
    plan: PlanDto
    newPlan: PlanDto
    subscription,
    subscriber,
    migrationStatus: string
  }>()
  const theme = useTheme() as { logo: { small: string, big: string } };

  const testGroups = [
    2850, 4117, 5306, 2201, 6964, 3354, 11185, 5979
  ]
  

  const [gatewayError, setGatewayError] = useState(false)
  const [appError, setAppError] = useState(false)
  const [success, setSuccess] = useState(false)

  const onSuccess = (subscription) => {
    setSuccess(true)
    setGatewayError(false)
    setAppError(false)
  }

  const onError = (error) => {
    if (error?.response?.data?.source === 'gateway') {
      setGatewayError(true)
    } else if (error?.response?.data?.source === 'application') {
      setAppError(true)
    } else {
      setAppError(true)
    }
    setSuccess(false)
  }

  const token = useMemo(() => getUrlParamByName('t'), [])

  const price = useMemo(() => {
    if (!!data?.newPlan) {
      const planDiscount = data?.newPlan?.discounts ? data?.newPlan?.discounts[0] : null;
      return (data?.newPlan?.price || 0) - (planDiscount?.amount || 0);
    }

    return 0
  }, [data?.newPlan])

  const publicKey = useMemo(() => {
    if (!!data?.newPlan) {
      return data?.newPlan?.gateway?.credentials?.public?.public_key || "";
    }

    return null
  }, [data?.newPlan])

  const discount = useMemo(() => {
    if (!!data?.newPlan) {
      const selectedPlanDiscount = data?.newPlan?.discounts[0]
      return selectedPlanDiscount && [selectedPlanDiscount]
    }

    return []
  }, [data?.newPlan])

  const user = useMemo(() => {
    return data?.subscriber?.user
  }, [data?.subscriber])


  const onSubmitTwo = useCallback(async (cardData) => {
    dispatch(showModal('modal-process-new-subscription'))

    const encryptedCardData = await hybridEncryptData(JSON.stringify(cardData)).catch((error) => {
      console.error("Encryption failed:", error);
      throw new Error("Failed to encrypt card data");
    });

    const payload = {
      old_subscription_id: data?.subscription?.id,
      plan_id: data?.newPlan?.id,
      user_id: data?.subscriber?.user?.id,
      subscriber_id: data?.subscriber?.id,
      discounts: discount,
      payment_details: {
        card_data: encryptedCardData,
        payer: {
          ...(process.env.REACT_APP_ENVIRONMENT !== "production" ? { email: "test_user_1799191595@testuser.com" } : { email: user?.email }),
          // email: user?.email
        }
      },
      platform: 'mercadopago'
    }

    action.Post({
      url: "/api/subscription/migration-subscription-change-payment-method",
      body: payload,
    }).then((response: Subscription) => {
      onSuccess(response)
    }).catch((error) => {
      onError(error)
    }).finally(() => {
      dispatch(hideModal())
    });
  }, [discount, price, data?.newPlan?.id]);

  const onSubmitOne = useCallback(async (cardData) => {
    dispatch(showModal('modal-process-new-subscription'))

    const payload = {
      old_subscription_id: data?.subscription?.id,
      plan_id: data?.newPlan?.id,
      user_id: data?.subscriber?.user?.id,
      subscriber_id: data?.subscriber?.id,
      discounts: discount,
      payment_details: cardData,
      platform: 'mercadopago'
    }

    action.Post({
      url: "/api/subscription/migration-subscription-change-payment-method",
      body: payload,
    }).then((response: Subscription) => {
      onSuccess(response)
    }).catch((error) => {
      onError(error)
    }).finally(() => {
      dispatch(hideModal())
    });
  }, [discount, price, data?.newPlan?.id]);

  useEffect(() => {
    if (!!token) {
      action.Get({
        url: "/api/subscription/migration-subscription-data",
        body: {},
        config: {
          headers: {
            "x-token": token
          }
        }
      }).then((response) => {
        setData(response)
      }).catch((error) => {
        console.log(error)
      });
    }
  }, [token]);

  useEffect(() => {
    if (data?.subscription?.migration_flag === 'migrated') {
      window.location.href = `/group/${data?.group?.slug}/library`
    }
  }, [data?.subscription])

  console.log({ data, publicKey, price, user })
  if (!data || !publicKey || !price || !user) {
    return <></>
  }

  return (
    <>
      <CheckoutContainer>
        {!gatewayError && !appError && !success && (
          <>
            <Column className='col-one'>
              <div style={{ gap: 24, display: 'flex', justifyContent: 'center', width: '100%', maxWidth: 410 }}>
                <Image
                  src={theme?.logo?.big}
                  style={{ display: 'block' }}
                  width="128px"
                  height="auto"
                  className="logo-desktop"
                  alt="Klouser"
                  lazy
                />

                <div style={{ height: 30, width: 1, backgroundColor: '#B1C6E4' }} />

                <Image
                  src={mercadoPagoImg}
                  style={{ display: 'block' }}
                  width="128px"
                  height="auto"
                  className="logo-desktop"
                  alt="Mercado pago"
                  lazy
                />
              </div>

              <div style={{ height: 30 }} />

              <PurchaseDetails
                style={{ maxWidth: 410 }}
                group={data?.group}
                plan={data?.newPlan}
                subscriber={data?.subscriber}
                subscription={data?.subscription}
              />
            </Column>

            <Column>
            {/* {testGroups.includes(data?.group?.id) ? ( */}
                <MpOwnForm
                  onError={onError}
                  onSuccess={onSuccess}
                  onSubmit={onSubmitTwo}
                  publicKey={publicKey || ""}
                  title={"Datos de pago"}
                  price={price}
                  submitText="Suscribirme"
                  user={user}
                />
              {/* ) : (
                <MercadoPagoForm
                  onError={onError}
                  onSuccess={onSuccess}
                  onSubmit={onSubmitOne}
                  publicKey={publicKey || ""}
                  title={"Datos de pago"}
                  price={price}
                  submitText="Suscribirme"
                  user={user}
                />
              )} */}

              <ShowInMobile>
                <div style={{ height: 35 }} />
                <Faqs isOpen={true} />
                <div style={{ height: 25 }} />
                <SecureTechnology />
              </ShowInMobile>

              <ShowInDesktop>
                <div style={{ height: 25 }} />
                <SecureTechnology />
              </ShowInDesktop>

            </Column>
          </>
        )}
      </CheckoutContainer>

      <ProcessSubscriptionModal waitingText='Aguarda mientras proocesamos tu nuevo método de pago'/>

      {(!gatewayError && appError && !success) && <ErrorSubscription text={<>Lamentablemente, la verificacion de tus<br />datos de pago ha fallado. No te<br />preocupes, estas cosas pasan.<br /><br />👉 Por favor, contáctate con nuestro<br />soporte para solucionarlo rápidamente.<br />¡Gracias por tu paciencia!</>} />}
      {(gatewayError && !appError && !success) && <ErrorPayment retryCtaText='Reintentar verificación' title={<>Verificación fallida</>}text={<>{!!user ? <>Hola {user?.name}</> : <></>}! No te preocupes, esto sucede frecuentemente. Alguno de los datos puede estar incorrectos, tu tarjeta es invalida o no es soportada para este tipo de pagos automáticos. <br/> <br/> Prueba reintentando con el mismo u otro método de pago o contacta a nuestro equipo de soporte</>} />}
      {(!gatewayError && !appError && success) && <Success group={data?.group} />}
    </>
  );
};

export default Component