import axios from 'axios';

const EU_COUNTRIES = [
  "AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR",
  "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK",
  "SI", "ES", "SE" // Add other EU country codes if needed
];

export const getCurrencyFromLocation = async () => {
  try {

    const response = await axios.get("https://geolocation-db.com/json/");

    const countryCode = response.data.country_code;

    // Determine currency based on country code
    if (EU_COUNTRIES.includes(countryCode)) {
      return "EUR"
    } else if (countryCode === "AR") {
      return "ARS"
    } else {
      return "USD"
    }

  } catch (error) {
    console.error('Error al obtener la ubicación:', error);
    return null;
  }
};