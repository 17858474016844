import Helmet from "react-helmet";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Alert from "../../../../components/Alert";
import Grid from "../../../../components/Grid";
import Members from "../../components/MembersList";
import { Page, PageHeader, Title } from "../../components/styles";
import { connect, useDispatch } from "react-redux";
import { getGroupById, getGroupSubscribers, getMetrics } from "../../../../store/actions/group";
import Input from "../../../../components/FormikForm/Input";
import ReactSelect from "../../../../components/FormikForm/ReactSelect";
import Submit from "../../../../components/FormikForm/Submit";
import Button from '../../../../components/Button'
import { Filters, FilterLabel } from "./styles";
import { Formik } from "formik";
import Tabs from "./../components/Tabs";
import InviteMembers from "../../components/MembersList/components/InviteMembers";
import {
  initialValues,
  schema,
} from "../../../../constants/forms/subscribers-filters";
import { Group, PlanDto } from "../../../../types";
import { useLayout } from "../../../../providers/LayoutProvider";

interface Filter {
  [field: string]: (string | number)[];
}

interface ComponentProps {
  match,
  group: Group;
  groupStates: {
    loading: boolean,
    error: string | boolean,
    success: boolean
  }
  groupSubscribersInformation;
  groupSubscribersStates;
}

const periodsUnitsTranslations = {
  "month": { singular: "mes", plural: "meses" },
  "quarter": { singular: "trimestre", plural: "trimestres" },
  "half-year": { singular: "semestre", plural: "semestres" },
  "year": { singular: "año", plural: "años" },
}

const Component = ({
  match,
  group,
  groupStates,
  groupSubscribersInformation,
  groupSubscribersStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const { layout, setLayout } = useLayout();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<{ value: string, label: string }[]>([])
  const [isOpened, setIsOpened] = useState(false);


  useEffect(() => {
    if (
      !!match.params &&
      (!group || (!!group && group?.id !== Number(match?.params?.idGroup))) &&
      !groupStates.loading
    ) {
      dispatch(getGroupById({ group_id: match.params.idGroup }));
      dispatch(getMetrics({ id: match.params.idGroup, filters: [] }));
    }
  }, [match]);

  const getSubscribres = () => {
    dispatch(getGroupSubscribers({ page, id: group?.id, filters }));
  };

  const paginate = (page) => {
    setPage(page);
  };

  const applyFilters = ({ values, actions }) => {
    const { status, ...rest } = values

    const apiStatuses = status.map((option) => {
      if (option === "payment_issue") {
        return ["renewal_retrying", "retrying", "not_paid"];
      }

      if (option === "all_cancelled") {
        return ["cancelled", "expired"]
      }
      return option;
    });

    setFilters({ ...rest, status: apiStatuses });
  };

  const uniqueCurrencies = useMemo(() => {
    return (group?.plans as PlanDto[])?.reduce((acc: { value: string, label: string }[], plan: PlanDto) => {
      if (!acc.some(item => item?.value === plan.currency)) {
        acc.push({ value: plan.currency, label: plan.currency });
      }
      return acc;
    }, []);
  }, [group]);

  const getFriendlyPeriod = (plan: PlanDto) => {
    if (plan.type === 'subscription') {
      const isPlural = plan?.period && plan?.period > 1;
      const period = plan?.period_unit && periodsUnitsTranslations[plan?.period_unit]
      return `Cada ${plan?.period} ${period[isPlural ? "plural" : "singular"]}`
    } else {
      return "Pago único"
    }
  }

  const generatePeriodList = useMemo((): {
    value: string; // Concatenación de plan_ids
    label: string; // Texto descriptivo del período
  }[] => {
    const plans = group?.plans;
    const currencies = selectedCurrency.map(item => item.value)
    // Filtrar planes activos y, si se proporciona, filtrar por moneda
    const filteredPlans = plans?.filter(
      plan => plan?.status === "active" && (currencies.length <= 0 || currencies.includes(plan?.currency))
    );

    // Agrupar planes por período y unidad
    const groupedByPeriod = filteredPlans?.reduce<Record<string, { value: string[]; label: string }>>(
      (acc, plan) => {
        const periodKey = plan?.period && plan?.period_unit
          ? `${plan?.period}-${plan?.period_unit}`
          : "one-time"; // Clave para pago único

        if (!acc[periodKey]) {
          acc[periodKey] = {
            value: [],
            label: plan?.period && plan?.period_unit
              ? getFriendlyPeriod(plan)
              : "Pago único",
          };
        }

        acc[periodKey].value.push(plan?.id.toString());
        return acc;
      },
      {}
    );

    if (groupedByPeriod) {
      // Convertir el resultado en un array de objetos
      return Object.values(groupedByPeriod)?.map(group => ({
        value: group?.value.join(", "),
        label: group?.label,
      }));
    }

    return []
  }, [group, selectedCurrency]);


  useEffect(() => {
    getSubscribres();
    console.log('filters', filters)
  }, [filters, page, group]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: group?.group_name,
        description: `Listado de miembros de tu membresía ${group?.group_name}.`,
        back: `/dashboard/${group?.group_type}/${group?.id}`,
      },
      dashboard: {
        tabs: {
          visible: true,
          activeTab: "members",
          component: [<Tabs active="members" />],
        },
      },
      header: {
        visible: true,
        actions: [<Button
          onClick={() => {
            setIsOpened(true)
          }}
          options={{
            type: "filled",
            size: "lg",
            skin: "purple",
          }}
        >
          Agregar Miembros
        </Button>]
      },
    });
  }, [group, isOpened]);

  return (
    <Grid.ContainerFluid>
      <Grid.Row>
        <Grid.Col>
          <Alert
            visible={true}
            title="Aumenta el numero de suscriptores!"
            text="No olvides promocionar tu grupo compartiendo el Link en tus redes sociales."
            id="aumenta-tus-suscriptores"
          />

          <div style={{ height: 30 }} />

          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              applyFilters({ values, actions });
            }}
            validateOnChange={true}
            validateOnBlur={false}
            validationSchema={schema}
            enableReinitialize
          >
            {({
              touched,
              errors,
              values,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <form
                  className="theme-form"
                  onSubmit={handleSubmit}
                  id="subscribers-list-filters"
                >
                  <FilterLabel>Filtros</FilterLabel>
                  <Filters>
                    <Input
                      name="email"
                      touched={touched["email"]}
                      error={errors["email"]}
                      value={values["email"]}
                      type="string"
                      placeholder="Email, nombre o apellido"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        marginBottom: 0,
                      }}
                    />

                    <ReactSelect
                      name="status"
                      touched={touched["status"]}
                      error={errors["status"]}
                      placeholder="Estado"
                      isClearable
                      isMulti
                      items={[
                        { value: "active", label: "Activo" },
                        { value: "all_cancelled", label: "Cancelado" },
                        { value: "non_renewing", label: "Proximo a vencer" },
                        { value: "pending_invite", label: "Invitado Pendiente" },
                        { value: "payment_issue", label: "Problemas con el pago" }
                      ]}
                      onChange={handleChange}
                      onOptionSelected={(field, data) => {
                        // if (
                        //   data.action === "remove-value" &&
                        //   filters[filterName]
                        // ) {
                        //   const filterValue = filters[filterName];
                        //   delete filters[filterName][data.removedValue.value];
                        //   console.log("filterValue", filterValue);
                        //   if (typeof filterValue === "object") {
                        //     handleChange({
                        //       [field.name]: filters[filterName],
                        //     });
                        //   }
                        // }
                      }}
                      onBlur={handleBlur}
                      options={{
                        marginBottom: 0,
                      }}
                    />

                    <ReactSelect
                      name="currency"
                      touched={touched["currency"]}
                      error={errors["currency"]}
                      placeholder="Moneda"
                      isClearable
                      isMulti
                      items={uniqueCurrencies}
                      onChange={handleChange}
                      onOptionSelected={(field, data) => {
                        setSelectedCurrency(field)
                      }}
                      onBlur={handleBlur}
                      options={{
                        marginBottom: 0,
                      }}
                    />

                    <ReactSelect
                      name="plan"
                      touched={touched["plan"]}
                      error={errors["plan"]}
                      placeholder="Periodo de pago"
                      isClearable
                      isMulti
                      items={generatePeriodList}
                      onChange={handleChange}
                      onOptionSelected={(field, data) => {
                      }}
                      onBlur={handleBlur}
                      options={{
                        marginBottom: 0,
                      }}
                    />


                  </Filters>

                  <FilterLabel>Ordenar por</FilterLabel>
                  <Filters>
                    <ReactSelect
                      name="sort_by"
                      touched={touched["sort_by"]}
                      error={errors["sort_by"]}
                      placeholder="Ordenar por"
                      isClearable
                      items={[
                        { value: "name:asc", label: "Nombre: A a Z" },
                        { value: "name:desc", label: "Nombre: Z a A" },
                        { value: "email:asc", label: "Email: A a Z" },
                        { value: "email:desc", label: "Email:  Z a A" },
                        { value: "started_at:desc", label: "Miembros nuevos primero" },
                        { value: "started_at:asc", label: "Miembros antigüos primero" },
                      ]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        marginBottom: 0,
                      }}
                    />
                  </Filters>

                  <Submit
                    isSubmmiting={groupSubscribersStates.loading}
                    form="subscribers-list-filters"
                    options={{
                      type: "link",
                      skin: "purple",
                      size: "lg",
                    }}
                  >
                    Aplicar filtros
                  </Submit>

                  {/* <AutoSave /> */}
                </form>
              );
            }}
          </Formik>

          <Members
            states={groupSubscribersStates}
            data={groupSubscribersInformation?.data}
            filters={filters}
            pagination={groupSubscribersInformation?.pagination}
            goToPage={paginate}
            groupId={group?.id}
          />

          <InviteMembers isOpened={isOpened} onClose={() => setIsOpened(false)} />
        </Grid.Col>
      </Grid.Row>
    </Grid.ContainerFluid>
  );
};

const state = ({ groupStore }) => {
  const { data: groupSubscribersInformation, states: groupSubscribersStates } =
    groupStore.groupSubscribers;
  const { data: group, states: groupStates } = groupStore.group;

  return {
    group,
    groupStates,
    groupSubscribersInformation,
    groupSubscribersStates,
  };
};

export default connect(state)(Component);
