import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Group } from "../../../../../types";
import Icon from '../../../../../components/Icon';
import { Section, SectionName, Title, Text } from "../StyledComponents";
import constants from "../../../../../constants";
import { List, ListItem } from "./styles";
import slugify from "slugify";
import { useTheme } from "styled-components";

interface ComponentProps {
  group: Group;
  groupStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ group }: ComponentProps) => {
  const theme = useTheme() as { howItsWorks: { icons: { color: string } } }
  const [howItWorks, setFaqs] = useState<{ icon: string; text: string }[]>();

  useEffect(() => {
    const configFilter = constants.pageGroupDetails.filter(
      (config) => config.type === group?.group_type && config.group_id === group?.id
    );

    let config;
    if (configFilter.length > 0)
      config = configFilter[0]
    else {
      config = constants.pageGroupDetails.filter(
        (config) => config.type === group?.group_type && !config.group_id
      )[0];
    }

    setFaqs(config?.howItWorks[group?.telegram_groups?.[0]?.platform || 'telegram']);
  }, [group]);

  return (
    <Section>
      <SectionName>es fácil, en solo 2 minutos </SectionName>
      <Title>¿Cómo funciona?</Title>
      <div style={{ height: "64px" }} />
      <List>
        {howItWorks?.map((item) => (
          <ListItem key={slugify(item.text, { lower: true })}>
            <Icon icon={item.icon} size="80px" {...(theme?.howItsWorks?.icons?.color ? { color: theme?.howItsWorks?.icons?.color } : {})} />
            <p>{item.text}</p>
          </ListItem>
        ))}
      </List>
    </Section>
  );
};

const state = ({ groupStore }) => {
  const { data: group, states: groupStates } = groupStore.group;

  return {
    group,
    groupStates,
  };
};

export default connect(state)(Component);
