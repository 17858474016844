import { useContext, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { signIn } from "../../../store/actions/auth";
import { Redirect } from "react-router-dom";
import SignIn from "../../components/SignInForm";
import { SubscriptionContext } from "../../../providers/Subscription";
import { subscriptionStates } from "../../../store/types/user/subscription";
import forward from "../../../helpers/fowardUrl";

const Component = ({ authStates, userStates, userInformation }) => {
  const [formSubmmited, setFormSubmmited] = useState<boolean>(false);
  const { subscriptionState, groupUrl } = useContext(SubscriptionContext);

  const dispatch = useDispatch();

  const onFormSubmit = ({ values }) => {
    dispatch(signIn(values));
  };

  if (!!userInformation) {
    if (subscriptionState === subscriptionStates.INPROGRESS) {
      return <Redirect to={groupUrl} />;
    }

    const redirectUrl = forward.getFowardUrl()

    if (!!redirectUrl) {
      forward.goFoward()
    } else {

      if (!!userInformation.subscriber) {
        return <Redirect to="/profile/subscriptions" />;
      }

      if (!!userInformation?.expert) {
        return <Redirect to="/dashboard" />;
      }

      return <Redirect to="/profile" />;
    }
  }

  return (
    <SignIn
      userType="subscriber"
      onFormSubmit={onFormSubmit
      }
      formSubmmited={formSubmmited}
      setFormSubmmited={setFormSubmmited}
    />
  );
};

const state = ({ authStore, userStore }) => {
  const { states: authStates } = authStore.signin;
  const { data: userInformation, states: userStates } = userStore.information;
  return {
    authStates,
    userStates,
    userInformation,
  };
};

export default connect(state)(Component);
